import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

  @font-face {
    font-family: "Motiva Sans Light";
    src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: "Motiva Sans Bold";
    src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    font-style: normal;
  }


  body,
  html,
  a {
    font-family: 'Motiva Sans Light', sans-serif;
  }


  body {
    margin:0;
    padding:0;
    border: 0;
    outline: 0;
    background: #fff;
    overflow-x: hidden;
  }

  a:hover {
    color: #18216d;
  }

  input,
  textarea {
    color: #fff;
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;  
    outline: none;
    width: 100%;  
    padding: 1rem 1.25rem;

    :focus-within {
      background: none;
      box-shadow: #2e186a 0px 0px 0px 1px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Motiva Sans Bold', serif;
    color: #18216d;
    font-size: 56px;
    line-height: 1.18;

    @media only screen and (max-width: 890px) {
      font-size: 47px;
    }
  
    @media only screen and (max-width: 414px) {
      font-size: 32px;
    }
  }

  p {
    color: #18216d;
    font-size: 21px;        
    line-height: 1.41;
  }

  h1 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    outline: none;
    color: #2E186A;

    :hover {
      color: #2e186a;
    }
  }
  
  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
  }

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
  .chatui {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;

    .container {
      width: 100%;
      padding: 20px 40px;
      max-width: 600px;
      background: #111;
      min-height: 400px;
      position: relative;
      .brand-logo {
        h5 {
          text-align: center;
          border-bottom: #333 solid 1px;
          color: #FFF;
          padding-bottom: 20px;
        }
        a.logout {
          position: absolute;
          top: 0px;
          right: 0px;
          padding: 10px;
          img {
            width: 20px;
            filter: brightness(1) contrast(0);
            }
          }
        }
      }
      .chat-section {
        text-align: center;
        padding-bottom: 20px;
        p {
          color: #fff;
        }
        .browse {
          position: relative;
          display: inline-block;
          margin: 0 auto 20px;

          label {
            display: block;
            color: #fff;
            padding: 10px;
            text-decoration: underline;
          }
          input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            padding: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 9;
            top: 0;
          }
        }
        .documents {
          ul {
            list-style-type: none;
          }
        }
        .deletefiles {
          color: #f00;
          border: none;
          cursor: pointer;
          background: none;
          margin-bottom: 10px;
        }
        textarea {
          background: rgba(255,255,255,0.1);
        }
        a {
          color: #fff;
          border: none;
          float: right;
          margin: 10px 0 20px;
          padding: 10px 20px;
          background: rgb(46, 24, 106);
        }
        .response {
          display: flex;
          width: 100%;
          p {
            font-size: 14px;
            color: #fff;
            text-align: left;
          }
        }
      }
      .chat-footer {
        text-align: center;
        border-top: #333 solid 1px;
        color: #FFF;
        padding-top: 20px;
        font-size: 16px;
      }
    }
  }
  .login-container {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    .container {
      width: 100%;
      padding: 20px 40px;
      max-width: 600px;
      background: #111;
      min-height: 400px;
      .brand-logo {
        h5 {
          text-align: center;
          border-bottom: #333 solid 1px;
          color: #FFF;
          padding-bottom: 20px;
          margin-bottom: 0;
        }
      }
      form {
        h6 {
          background: rgb(51, 51, 51);
          display: inline-block;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          top: -20px;
          margin: 0;
          font-size: 14px;
          text-transform: uppercase;
          color: #fff;
          padding: 10px 20px;
        }
        label {
          color: #fff;
        }
        input {
          background: #1a1a1a;
          ::placeholder {
            color: #999;
          }
        }
        .sign-msg {
          color: white;
          float: left;
          font-size: 14px;
          line-height: 50px;
          margin-bottom: 0;
          margin-top: 0.625rem;
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
        button {
          border: none;
        }
        &.loginwith {
          padding-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          h6 {
            position: absolute;
          }
          button {
            float: unset;
            display: block;
            cursor: pointer;
            margin: 0 auto 10px;

            img {
              width: 20px;
              margin-right: 10px;
            }
          }
          a {
            color: #fff;
            padding: 5px 0;
            display: inline-block;
            margin: auto;
            clear: both;

            :hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .btn {
    color: #fff;
    border: none;
    float: right;
    margin: 10px 0 20px;
    padding: 10px 20px;
    background: rgb(46, 24, 106);
    :hover {
      color: #fff;
      background: #000;
    }
  }
`;
